import {
	Component,
	OnInit,
	VERSION, NgZone
} from '@angular/core';
import {
	NavigationEnd,
	Router
} from '@angular/router';
import { filter } from 'rxjs/operators';
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, OnDestroy } from '@angular/core';
import { NavigationService } from '../services/navigation.service';
import { environment } from 'src/environments/environment';
import { CommonService } from '../dealer/services/common.service';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

@Component({
	selector: 'common-layout',
	templateUrl: './common-layout.component.html',
	styleUrls: ['./common-layout.component.scss']
})
export class CommonLayoutComponent implements OnInit {
	public hostUrl = environment.hostUrl;
	mobileQuery: MediaQueryList;
	/** string used to hold the url for the skipToMain link */
	skipToMain: string;
	dealerId: string;
	userId: string;
	isProfileClicked = false;
	dealerCode: any[]
	errorMsg: boolean = false;
	selectedCategory: any;
	displayMaximizable: boolean;
	isActiveDirectory: boolean;
	/** the Angular version */
	/** constructor for setting up DI in this component */
	private _mobileQueryListener: () => void;
multiDealerEnable:boolean = false;
	constructor(private readonly router: Router, changeDetectorRef: ChangeDetectorRef,
				private dealerService: CommonService,
				private zone: NgZone,
				private iab: InAppBrowser,
				media: MediaMatcher, private navigation: NavigationService) {
		this.mobileQuery = media.matchMedia('(max-width: 600px)');
		this._mobileQueryListener = () => changeDetectorRef.detectChanges();
		this.mobileQuery.addListener(this._mobileQueryListener);
	}

	/** this class requires this method to implement the OnInit interface */
	ngOnInit() {
		this.router.events.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe((event: NavigationEnd) => {
				this.setSkipLinkUrl(event.urlAfterRedirects);
			});
		const tokenInfo = this.getTokenInfo(sessionStorage['gateway_access_token']);
		console.log(tokenInfo);
		const id = tokenInfo.sub;
		const countryCode = tokenInfo.country;
		console.log('******** Login Detail ******************')
		console.log(id);
		this.userId = id;
		sessionStorage['dealerId'] = id;
		console.log(countryCode);
		console.log(sessionStorage['dealerToken']);
		this.isActiveDirectory = JSON.parse(sessionStorage.getItem('isActiveDirectory'));
		this.dealerId = sessionStorage['dealerCode'];

		// if (sessionStorage['dealerToken'] == 'Test') {
			if (sessionStorage['dealerToken'] == 'Test' && tokenInfo.ACIGROUP === 'Dealer')
{

			this.fetchDealerCode();
		}

		else{
			sessionStorage['dealerCode'] = '';
		}
		this.multiDealerEnable = sessionStorage['multiDealer'];
	}

	fetchDealerCode() {
		this.dealerService.fetchDealerCode(sessionStorage['dealerId']).subscribe(res => {
			this.responseHandler(res);
		});
	}

	swapDealer() {
		this.fetchDealerCode();
		this.selectedCategory = sessionStorage['dealerCode'];
	}

	navigateToBackOrder() {

		this.navigation.isBackActive = false;
		this.router.navigateByUrl('/dealer/dealer-backorder');
	}

	getTokenInfo(token: string): any {
		return JSON.parse(atob(token.split('.')[1]));
	}

	/**
	 * setSkipLinkUrl takes in a url string and processes whether
	 * the skipToMain link should be updated to use the new value
	 * @param currentUrl the new url to refer to
	 */
	private setSkipLinkUrl(currentUrl: string) {
		if (!currentUrl.endsWith('#app-content')) {
			this.skipToMain = `${currentUrl}#app-content`;
		}
	}

	showProfile(event) {
		this.isProfileClicked = !this.isProfileClicked;
		event.stopPropagation();
	}

	hideProfile() {
		this.isProfileClicked = false;
	}

	stopPropogation(event) {
		event.stopPropagation();
	}
	logout() {
		console.log('*** Logout ***');
		localStorage.clear();
		sessionStorage.clear();
		document.location.href = 'https://wwwqa.faust.idp.ford.com/logout?back=' + this.hostUrl;
	}
// 	logout() {
// 		if (environment.device == 'web') {
// 			document.location.href = 'https://wwwqa.faust.idp.ford.com/logout?back=' + this.hostUrl;
// 		} else {
// 			console.log('********* Open ADFS ******')
// 			this.isProfileClicked = false;
// 			sessionStorage['logout'] = 'Test'
// 			if(this.router.url==='/dashboard'){
// 				console.log('*** Logout ***');
// 				localStorage.clear();
// 				sessionStorage.clear();
// 				console.log('********* Open ADFS ******')
// 				sessionStorage['dealerToken'] = 'Test'
// 				const browser = this.iab.create(environment.accessTokenUrl +
// 				  '/adfs/oauth2/authorize/wia?response_type=token+id_token&client_id=' + environment.webClientId + '&resource=' + environment.webResourceId + '&' +
// 				  'redirect_uri=' + environment.hostUrl + 'oauth-callback', '_blank');
// 				browser.show();
// 				browser.on('loadstart').subscribe(event => {
// 				  console.log("*** On Load Start Event ****");
// 				  console.log(event.url);
// 				  if (event.url.indexOf('access_token') > 0) {
// 					sessionStorage['gateway_access_token'] = event.url.split('access_token=')[1]
// 					sessionStorage['dealerToken'] = 'Test'

// 					this.zone.run(() => {
// 					  browser.close();
// 					  const brow = this.iab.create('https://wwwqa.faust.idp.ford.com/logout?back=' + environment.hostUrl + '/dash', '_blank');
// 					  brow.on('loadstart').subscribe(event => {
// 						if (event.url.indexOf('dash') > 0) {
// 						  this.zone.run(() => {
// 						   window.location.reload();
// 						  });
// 						  brow.close();
// 						}
// 					  });
// 					});
// 				  }
// 				});

// 			} else {
// 				this.router.navigate(['dashboard']);
// 			}
// 		}

// 	}


	fcdHome()
	{
		sessionStorage.removeItem('isActiveDirectory');
		window.location.href = this.hostUrl + 'index';
	}

	responseHandler(response) {
		this.dealerCode = response;
		console.log('** Size **');
		console.log(response.length);
		if (response.length > 1) {
			sessionStorage['multiDealer'] = true;
			this.multiDealerEnable = true;
			this.displayMaximizable = true;
		} else {
			sessionStorage['dealerCode'] = response[0];
			this.dealerId = response[0];
			sessionStorage['dealerToken'] = '';
		}
	}

	setDealer() {
		console.log('*** Dealer Code ***');
		if (this.selectedCategory) {
			console.log(this.selectedCategory);
			sessionStorage['dealerCode'] = this.selectedCategory;
			sessionStorage['dealerToken'] = '';
			this.dealerId = this.selectedCategory;
			this.displayMaximizable = false;
		} else {
			console.log('*** Error Msg ***');
			this.errorMsg = true;
		}
	}

}
