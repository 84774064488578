import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, NgZone } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from 'src/environments/environment';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
@Injectable()
export class AuthGuardService implements CanActivate {

	constructor(private zone: NgZone,
		 private iab: InAppBrowser,
		 private router: Router, @Inject(DOCUMENT) private document) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		sessionStorage['activeContent'] = false;
		if (sessionStorage['dealerId'] != null) {
			sessionStorage['activeContent'] = true;
			return true;
		}else if(JSON.parse(sessionStorage.getItem('commonIndex'))){
			sessionStorage.setItem('commonIndex', JSON.stringify(false));
			window.location.href=environment.hostUrl+'index';
		}else{
			window.location.href=environment.hostUrl;
		}
		
		if (environment.device == 'web') {
			document.location.href = environment.accessTokenUrl +
				'/adfs/oauth2/authorize/wia?response_type=token+id_token&client_id='
				+ environment.webClientId + '&resource=' + environment.webResourceId + '&' +
				'redirect_uri=' + environment.hostUrl + 'oauth-callback';
		}
//  else {
		// console.log('********* Open ADFS ******')
		// sessionStorage['dealerToken'] = 'Test'
		// const browser = this.iab.create(environment.accessTokenUrl +
		// 	'/adfs/oauth2/authorize/wia?response_type=token+id_token&client_id=' + environment.webClientId + '&resource=' + environment.webResourceId + '&' +
		// 	'redirect_uri=' + environment.hostUrl + 'oauth-callback', '_blank',"location=no,clearsessioncache=yes");
		// browser.show();
		// browser.on('loadstart').subscribe(event => {
		// 	console.log("*** On Load Start Event ****");
		// 	console.log(event.url);
		// 	if (event.url.indexOf('access_token') > 0) {
		// 		sessionStorage['gateway_access_token'] = event.url.split('access_token=')[1]
		// 		sessionStorage['dealerToken'] = 'Test'

		// 		this.zone.run(() => {
		// 			browser.close();

		// 			const brow = this.iab.create('https://wwwqa.faust.idp.ford.com/logout?back=' + environment.hostUrl + '/dash', '_blank');

		// 			brow.on('loadstart').subscribe(event => {
		// 				if (event.url.indexOf('dash') > 0) {
		// 					brow.close();
		// 					this.zone.run(() => {
		// 						this.router.navigate(['dashboard']);
		// 					});
		// 				}
		// 			});
		// 		});
		// 	}
		// });
		// }
		return false;
	}
}
