/**This file contains a constant object that has properties that are necessary
 * for the build found in the `development` configuration in `angular.json`.
 */
 export const environment = {
	production: true,
	environmentName: 'prod',
	partUrl: 'https://api.mss.ford.com/panda/',
	orderUrl: 'https://api.mss.ford.com/panda-order/',
	webUrl: 'https://panda-service-web.apps.pp01i.edc1.cf.ford.com/api/apic/roles/',

	hostUrl: 'https://www.panda.ford.com/',
	// 	 https://panda-responsive-prod.apps.pd01i.edc1.cf.ford.com/
	webClientId: 'urn:pandaexternalapp:clientid:web_dealersupplierappprod:prod',
	webResourceId: 'urn:pandaexternalapp:resource:web_dealersupplierappprod:prod',
	accessTokenUrl: 'https://corp.sts.ford.com',
	baseUrl: 'https://api.mss.ford.com/panda-supplier/api/',
	// https://panda-vendor-prod.apps.pd01i.edc1.cf.ford.com/panda-supplier/api/
	device:'web'
	// include development api base url string here as a property
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
