<!-- Part Number Search-->
<!-- Search Button -->
<div class="container-fluid">
    <button type="search" (click)="op.toggle($event); checkPart()" class="btn btn_secondary m-2 p-2" id="part-search-button-P20"><i
            class="pi pi-window-maximize"></i><span class="mr-1"> &nbsp;Search Part No.</span></button>
</div>
<!-- Part Number data population Overlaypanel -->
<div class="containespanelr">
    <div class="row">
        <p-overlayPanel #op [showCloseIcon]="true" [dismissable]="true" (onShow)="onShow()">
            <div class="progress-spinner" *ngIf="isSpinner==0">
                <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
                fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
                <div class="h5" style="color: #00095b; margin-top:3%">Loading Please Wait!</div>
            </div>
            <ng-template pTemplate>
                <p-table #dt1 [value]="partNumberDatas" #paginatortablePartData
                    styleClass="p-datatable-responsive-demo p-datatable-striped p-datatable-gridlines"
                    selectionMode="single" [(selection)]="selectedProduct" (onRowSelect)="onRowSelect($event)"
                    [paginator]="true" [rows]="5" responsiveLayout="scroll" *ngIf="partNumberDatas" [globalFilterFields]="['gpfNpno']">
                    <!-- [globalFilterFields]="['gpfNpno']" -->
                    <ng-template pTemplate="caption">
                        <div class="p-d-flex">
                            <span class="p-input-icon-right p-ml-auto">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text"
                                    (input)="checkKey($event.target.value)"  placeholder="" />
                                    <!--  (input)="dt1.filterGlobal($event.target.value, 'contains')" -->
                            </span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th id="head-cell" pSortableColumn="partNumber" style="width: 27%;text-align: center;"><span class="table-head">Service Part Number</span>
                                <p-sortIcon field="partNumber"></p-sortIcon>
                            </th>
                            <th id="head-cell" pSortableColumn="partNumber" style="width: 27%;text-align: center;"><span class="table-head">Engineering Part Number</span>
                                <!-- <p-sortIcon field="partNumber"></p-sortIcon> -->
                            </th>
                            <th id="head-cell" pSortableColumn="description" style="width: 30%;text-align: center;"><span class="table-head">Part
                                Description</span></th>
                            <th id="head-cell" pSortableColumn="invStatus" style="width: 16%;text-align: center;"><span class="table-head">Inv Status</span>
                                <p-sortIcon field="invStatus"></p-sortIcon>
                            </th>

                            <th style="display: none" pSortableColumn="refNo">ReferenceNo <p-sortIcon field="refNo">
                                </p-sortIcon>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-product>
                        <tr [pSelectableRow]="rowData">
                            <td id="pop-up-col"><span class="p-column-title">PartNumber</span><span
                                    class="cell-content">{{product.gpfNpno}}</span></td>
                            <td id="pop-up-col"><span class="p-column-title">EngineeingPartNumber</span><span
                                class="cell-content">{{product.hvyEpNo}}</span></td>

                            <td id="pop-up-col"><span class="p-column-title">Description</span><span
                                    class="cell-content">{{product.descNounAndSfx}}</span></td>
                                    <td id="pop-up-col"><span class="p-column-title">InvStatus</span><span
                                        class="cell-content">{{product.invStatusValues}}</span></td>
                            <td id="pop-up-col" style="display: none">{{product.gpfRefNo}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </ng-template>
        </p-overlayPanel>
    </div>
</div>

<!-- Part data Display-->
<div class="content">
    <div class="row">
        <div class="col-lg-3 col-md-2 col-sm-6 ">
            <div class="item-label">
               Service Part Number</div>
            <div class="item-description-p20">
                <pre>{{partNumber}}</pre>
            </div>
        </div>
        <div class="col-lg-3 col-md-2 col-sm-6">
            <div class="item-label">Engineering Part Number</div>
            <div class="item-description">
                <div>{{engineeringPartNumber}}</div>
            </div>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-6">
            <div class="item-label">Part Description</div>
            <div class="item-description">
                <div>{{partName}}</div>
            </div>
        </div>

        <div class="col-lg-1 col-md-2 col-sm-6">
            <div class="item-label">Status</div>
            <div class="item-description">
                <div>{{status}}</div>
            </div>

        </div>
        <div class="col-lg-3 col-md-3 col-sm-12">
            <div class="item-label">Consolidated Part Details</div>
            <div class="item-description" id="consolidatedDataId">
                <div>
                    <i #icon class="pi pi-window-maximize clickable-icon" (click)="openConsolidatedPanel($event, icon)" 
                       aria-label="Show consolidated part details"></i>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Part Details table population -->
<div id="table-data">
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="card">
                        <div class="m-1 p-2 text-center" id="table-heading">Schedule Details by Part</div>
                        <p-table [value]="partNumberDetailsData" #paginatortable
                            styleClass="p-datatable-responsive-demo p-datatable-striped p-datatable-gridlines"
                            [paginator]="true" [rows]="5" [showCurrentPageReport]="true"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            tableStyleClass="table-border" [rowsPerPageOptions]="[5,10,15]">
                            <ng-template pTemplate="header">
                                <tr>
                                    <!--<th id="p20-tbl-cell-heading" style="width: 7.5%;" class="text-center" colspan="2">
                                        Order Type</th>-->
                                    <th id="p20-tbl-cell-heading" style="width: 8%;" class="text-center" rowspan="2">
                                        Order No</th>
                                    <th id="p20-tbl-cell-heading" style="width: 13%;" class="text-center" rowspan="2">
                                        Order Created Date</th>
                                    <th id="p20-tbl-cell-heading" style="width: 10%;" class="text-center" rowspan="2">
                                        Delivery Date</th>
                                    <th id="p20-tbl-cell-heading" style="width: 10%;" class="text-center" rowspan="2">
                                        Ex.Works Date</th>
                                    <th id="p20-tbl-cell-heading" style="width: 8%;" class="text-center" rowspan="2">
                                        Order Qty</th>
                                    <th id="p20-tbl-cell-heading" style="width: 10%;" class="text-center" rowspan="2">
                                        Date Satisfied</th>
                                    <th id="p20-tbl-cell-heading" style="width: 9%;" class="text-center" rowspan="2">
                                        Outstanding <p-inputSwitch [(ngModel)]="outStandingSwitch"
                                            (onChange)="onOutStandingSwitchChange($event)"></p-inputSwitch>
                                    </th>
                                    <th id="p20-tbl-cell-heading" style="width: 12.5%;" class="text-center" colspan="2">
                                        Cumulative</th>
                                    <th id="p20-tbl-cell-heading" style="width: 10%;" class="text-center" rowspan="2">
                                        Supplier Promise Date</th>
                                    <th id="p20-tbl-cell-heading" style="width: 8%;" class="text-center" rowspan="2">
                                        Comment</th>
                                </tr>
                                <tr>
                                    <!--<th id="sub-cell" class="text-center"></th>
                                    <th id="sub-cell" class="text-center"></th>-->
                                    <th id="sub-cell" class="text-center">Orders</th>
                                    <th id="sub-cell" class="text-center">Receipts</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-partOrderStatus>
                                <tr>
                                    <!--<td id="cell-content"><span class="p-column-title">Order Type</span><span
                                            class="cell-content">  </span></td>
                                    <td id="cell-content"><span class="p-column-title">Order Type</span><span
                                            class="cell-content">{{partOrderStatus.ordType}}</span></td>-->
                                    <td id="cell-content"><span class="p-column-title">Order No</span><span
                                            class="cell-content">{{partOrderStatus.orderNo}}</span></td>
                                    <td id="cell-content"><span class="p-column-title">Order Created Date</span><span
                                            class="cell-content">{{partOrderStatus.cretDate | date:'dd-MM-yyyy'}}</span>
                                    </td>
                                    <td id="cell-content"><span class="p-column-title">Delivery Date</span><span
                                            class="cell-content">{{partOrderStatus.delvDate | date:'dd-MM-yyyy'}}</span>
                                    </td>
                                    <td id="cell-content"><span class="p-column-title">Ex.Works Date</span><span
                                            class="cell-content">{{partOrderStatus.exwYmd | date:'dd-MM-yyyy'}}</span>
                                    </td>
                                    <td id="cell-content"><span class="p-column-title">Order Qty</span><span
                                            class="cell-content" style="float: right">{{partOrderStatus.authQty}}</span>
                                    </td>
                                    <td id="cell-content"><span class="p-column-title">Date Satisfied</span><span
                                            class="cell-content">{{partOrderStatus.satisfied}}</span></td>
                                    <td id="cell-content"><span class="p-column-title">Outstanding</span><span
                                            class="cell-content"
                                            style="float: right">{{partOrderStatus.osQtyValue}}</span></td>
                                    <td id="cell-content"><span class="p-column-title">Orders</span><span
                                            class="cell-content"
                                            style="float: right">{{partOrderStatus.cumOrder}}</span></td>
                                    <td id="cell-content"><span class="p-column-title">Receipts</span><span
                                            class="cell-content"
                                            style="float: right">{{partOrderStatus.cumReceipt}}</span></td>
                                    <td id="cell-content"><span class="p-column-title">Supplier Promise Date</span><span
                                            class="cell-content">{{partOrderStatus.estimatedTimeToDelv |
                                            date:'dd-MM-yyyy'}}</span></td>
                                    <td id="cell-content"><span class="p-column-title">Comments</span><span
                                            id="view-comment" class="cell-content" *ngIf="partOrderStatus.osQtyValue">
                                            <button pButton pRipple data-toggle="modal" data-target="#viewP20Comments"
                                                class="p-button-rounded p-button-info p-ripple p-button p-component p-button-icon-only ml-2"
                                                id="option" (click)="displayComments(partOrderStatus)"><i
                                                    class="pi pi-exclamation-circle"
                                                    style="font-size: 1.3rem;"></i></button>
                                        </span></td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Consolidated Part data overlaypanel -->
<div class="consolidatePartData">
    <div class="row">
        <p-overlayPanel #consolidatePartData [showCloseIcon]="true" [dismissable]="false" 
            [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" 
            [autoZIndex]="true" styleClass="consolidated-data-panel" appendTo="body"
            (onShow)="onPanelShow()" (onHide)="onPanelHide()">
            <ng-template pTemplate>
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                        <div class="card">
                            <div class="m-1 p-2 text-center" id="table-heading">This Year Base</div>
                            <p-table [value]="products" styleClass="p-datatable-gridlines p-datatable-striped">
                                <ng-template pTemplate="header">

                                </ng-template>
                                <ng-template pTemplate="body" let-data>
                                    <tr>
                                        <td id="head-cell">
                                            {{data.field}}
                                        </td>

                                        <td id="cell-content" style="text-align: right;">
                                            {{data.header}}
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                        <div class="card">
                            <div class="m-1 p-2 text-center" id="table-heading">Total Qty</div>
                            <p-table [value]="totalData" styleClass="p-datatable-gridlines p-datatable-striped">
                                <ng-template pTemplate="header">
                                </ng-template>
                                <ng-template pTemplate="body" let-data>
                                    <tr>
                                        <td id="head-cell">
                                            {{data.field}}
                                        </td>
                                        <td id="cell-content" style="text-align: right;">
                                            {{data.header}}
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
            </ng-template>
        </p-overlayPanel>
    </div>
</div>

<!-- Fast path for Intransit Inquiry -->
<div class="container">
    <div class="row">
        <div class="col-lg-2 col-md-6 col-sm-6 col-6">
            <button type="search" (click)="showSuccess()" class="btn btn_secondary m-2 p-2" id="fastpath-button-p20"><i
                    class="pi pi-external-link"></i><span class="mr-1"> &nbsp;In Transit Inquiry</span></button>
        </div>
        <div class="col-lg-2 col-md-6 col-sm-6 col-6">
            <button class="btn btn_secondary m-2 p-2" id="cancel-button-p20" (click)="redirectToHome()"
                type="button">Cancel</button>
        </div>
    </div>
</div>

<!-- Required for message service -->
<p-toast></p-toast>

<p-toast position="bottom-center" key="c" (onClose)="onReject()" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1">
            <div class="p-text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <h4>{{message.summary}}</h4>
                <p>{{message.detail}}</p>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-6">
                    <button type="button" pButton (click)="onConfirm()" label="Yes" class="p-button-success"></button>
                </div>
                <div class="p-col-6">
                    <button type="button" pButton (click)="onReject()" label="No" class="p-button-secondary"></button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>


<!-- Modal to view Comments and Add comments -->
<div class="modal fade" id="viewP20Comments" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
        <div class="modal-content">
            <div class="modal-body">
                <div class="container-fluid mt-3">
                    <div class="card">
                        <div class="m-1 p-2 text-center" id="table-heading">Comments History</div>
                        <p-table [value]="commentDetails"
                            styleClass="p-datatable-responsive-demo p-datatable-striped p-datatable-gridlines"
                            [paginator]="true" [rows]="5" [showCurrentPageReport]="true"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            tableStyleClass="table-border" [rowsPerPageOptions]="[5,10,15]">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 8%;" id="cmt-tbl-cell-heading">User Type</th>
                                    <th style="width: 8%;" id="cmt-tbl-cell-heading">User ID</th>
                                    <th style="width: 17%;" id="cmt-tbl-cell-heading">Created Time</th>
                                    <th style="width: 10%;" id="cmt-tbl-cell-heading">Delivery Date</th>
                                    <th style="width: 10%;" id="cmt-tbl-cell-heading">Ex.Works Date</th>
                                    <th style="width: 10%;" id="cmt-tbl-cell-heading">Supplier Promise Date</th>
                                    <th style="width: 40%;" id="cmt-tbl-cell-heading">Comments</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-comment>
                                <tr>
                                    <td id="data-cell"><span class="p-column-title">User Type</span><span
                                            class="cell-content">{{comment.supUserType}}</span></td>
                                    <td id="data-cell"><span class="p-column-title">User ID</span><span
                                            class="cell-content">{{comment.supCreatedBy}}</span></td>
                                    <td id="data-cell"><span class="p-column-title">Created Time</span><span
                                            class="cell-content">{{comment.supCreatedTimestamp | date:'medium'}}</span>
                                    </td>
                                    <td id="data-cell"><span class="p-column-title">Delivery Date</span><span
                                            class="cell-content">{{comment.supDelvYmd | date:'dd-MM-yyyy'}}</span></td>
                                    <td id="data-cell"><span class="p-column-title">Ex.Works Date</span><span
                                            class="cell-content">{{comment.supExworksDate | date:'dd-MM-yyyy'}}</span>
                                    </td>
                                    <td id="data-cell"><span class="p-column-title">Supplier Promise Date</span><span
                                            class="cell-content">{{comment.supEtdYmd | date:'dd-MM-yyyy'}}</span></td>
                                    <td id="data-cell"><span class="p-column-title">Comments</span><span
                                            class="cell-content">{{comment.supComments}}</span></td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <div class="alert alert-primary text-center mt-2" role="alert" *ngIf="isComment">
                        No comments Present!
                    </div>
                    <button type="button" style="float: left;" class="btn btn_secondary"
                        (click)="activateComment()">Add</button>
                    <button type="button" style="float: right;" class="btn btn_secondary"
                        (click)="closeModal()">Close</button>
                    <button type="button" id="close-comment-modal" hidden class="btn btn_secondary"
                        data-dismiss="modal">Close</button>
                    <br>
                    <div class="container form-text mt-4" id="add-comment" *ngIf="isAddComment">
                        <form #partComment="ngForm" id="comment-form" (ngSubmit)="submitComment()" novalidate>
                            <div class="alert alert-success text-center mt-2" role="alert" *ngIf="isCommentAdded">
                                Comment Added Successfully!
                            </div>
                            <div class="alert alert-danger text-center mt-2" role="alert" *ngIf="isCommentData">
                                Please enter either Comment or Estimated Date!
                            </div>
                            <div class="text-center h2 m-2 p-2 form-heading">Add Comment</div>
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                                    <div class="form-group" id="label-text">
                                        <label class="form-heading">Part Number: </label>
                                        <input type="text" id="" class="form-control" disabled [value]="partNumber">
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                                    <div class="form-group" id="label-text">
                                        <label class="form-heading">Vendor Code: </label>
                                        <input type="text" id="" [value]="vendorCode" class="form-control" disabled>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                                    <div class="form-group" id="label-text">
                                        <label class="form-heading">Part Description: </label>
                                        <input type="text" id="" class="form-control" disabled [value]="partName">
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                                    <div class="form-group" id="label-text">
                                        <label class="form-heading">Order Created Date: </label>
                                        <input type="text" id=""
                                            [value]="partOrderStatusData?.cretDate | date:'dd-MM-yyyy'"
                                            class="form-control" disabled>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                                    <div class="form-group" id="label-text">
                                        <label class="form-heading">Ex.Works Date: </label>
                                        <input type="text" id="" class="form-control" disabled
                                            [value]="partOrderStatusData?.exwYmd | date:'dd-MM-yyyy'">
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                                    <div class="form-group" id="label-text">
                                        <label class="form-heading"> Order Qty: </label>
                                        <input type="text" id="" [value]="partOrderStatusData?.authQty"
                                            class="form-control" disabled>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                                    <div class="form-group" id="label-text">
                                        <label class="form-heading">Outstanding: </label>
                                        <input type="text" id="" [value]="partOrderStatusData?.osQtyValue"
                                            class="form-control" disabled>
                                    </div>
                                </div>
                                <div class="col-lg-9 col-md-9 col-sm-12 col-12">
                                    <div class="form-group" id="label-text">
                                        <label class="form-heading">Comments: </label>
                                        <textarea rows="4" cols="50" name="comments" id="comments" class="form-control"
                                            ngDefaultControl [(ngModel)]="comment" #comments="ngModel" name="comments"
                                            required></textarea>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-12 col-12">
                                    <div class="form-group" id="label-text">
                                        <label class="form-heading">Supplier Promise Date: </label>
                                        <p-calendar name="expDate" [(ngModel)]="estimatedDate" dateFormat="dd-mm-yy"
                                            [minDate]="minDate" placeholder="dd-mm-yyyy" showIcon="true"
                                            inputId="estimatedDateID" ngDefaultControl ngModel #expDate="ngModel"
                                            required></p-calendar>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6 text-center">
                                    <div class="form-group">
                                        <button type="submit" class="btn btn_secondary">Submit</button>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6 text-center">
                                    <div class="form-group">
                                        <button type="button" class="btn btn_secondary" id="button-close"
                                            (click)="closeComment()">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
